import * as React from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import DashboardIcon from '@mui/icons-material/Dashboard';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import PersonIcon from '@mui/icons-material/Person';

import Typography from '@mui/material/Typography';
import { NavLink, useLocation } from 'react-router-dom';
import { useTheme } from '@emotion/react';


const Sidebar = ({drawerWidth, mobileOpen, setMobileOpen}) => {
    const theme = useTheme()
    const location = useLocation();
    // const { window } = props;
    // const [mobileOpen, setMobileOpen] = React.useState(false);
    console.log("Location : "+location.pathname)
    const handleDrawerToggle = () => {
      setMobileOpen(!mobileOpen);
    };

    const menuItems = [
        { text: 'Dashboard', path: '/', key: 'dashboard', icon:<DashboardIcon/> },
        { text: 'Members', path: '/members', key: 'members', icon:<PersonIcon/>},
        { text: 'Frais de Transport', path: '/claims', key: 'claims', icon:<DirectionsBusIcon/>},
        // Add more menu items as needed
      ];

    const navLinkStyle = {
    textDecoration: 'none',
    color: theme.palette.primary.main,
    };
  
    const drawer = (
      <div>
        <Box sx={{height: "50px", display: "flex", alignItems: "center", justifyContent: "center"}}>
            <Typography sx={{fontSize: '20px', fontWeight: 'bold', color: '#6439ff'}}>EduCommute</Typography>
        </Box>
        <Divider />
        <List>
            {menuItems.map((item) => (
            <ListItemButton
                key={item.key}
                component={NavLink}
                to={item.path}
                selected={location.pathname === item.path}
                style={navLinkStyle}
            >
                <ListItemIcon sx={{color: theme.palette.primary.main}}>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} />
            </ListItemButton>
            ))}
        </List>
        <Divider />
      </div>
    );
  
    return (
        <>
          <Drawer
            // container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: 'block', sm: 'none' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: 'none', sm: 'block' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
            open
          >
            {drawer}
          </Drawer>
        </>
    );
}

export default Sidebar;