import React, { useEffect, useState } from 'react'
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios'

const Datatable = () => {
  
  const [listMember, setListMember] = useState([])

  useEffect(()=>{
    const getData = async () =>{
      try{
        const data = await axios.get(`${process.env.REACT_APP_EDUCOMMUTE_LOCAL_API_ENDPOINT}/members`);
        // Transform data: Rename "member_id" to "id"
        const transformedData = data.data.map((row) => ({ ...row, id: row.member_id }));
        
        console.log(transformedData)
        setListMember(transformedData)
        console.log(listMember)
      }catch(err){
        console.error("error",err.code);
        setListMember({})
      }
    }
    getData();
  },[])

  const columns = [
    { field: 'matricule', headerName: 'Matricule', width: 130 },
    { field: 'last_name', headerName: 'Nom', width: 130},
    { field: 'first_name', headerName: 'Prénom', width: 130 },
    {
      field: 'email_adress',
      headerName: 'Adress mail',
      width: 200,
    },
    {
      field: 'iban_number',
      headerName: 'Numéro du compte',
      width: 200,
    },
    {
      field: 'status',
      headerName: 'Statut du compte',
      width: 160,
      
    }
  ];
  

  return (
    <div className='datatable'>
     <DataGrid
        rows={listMember}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10]}
        checkboxSelection
      />
    </div>
  )
}

export default Datatable