import React from 'react'
import Navbar from '../components/navbar/Navbar'
import Sidebar from '../components/sidebar/Sidebar'
import { Outlet } from 'react-router-dom'
import { Box, CssBaseline, Grid, Toolbar} from '@mui/material'

const drawerWidth = 180;

const MainLayout = () => {
    const [mobileOpen, setMobileOpen] = React.useState(false);
    return (  
    <Grid sx={{ display: 'flex' }}>
        <CssBaseline/>
        <Navbar drawerWidth={drawerWidth} mobileOpen={mobileOpen} setMobileOpen={setMobileOpen}/>
        <Box component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders">
            <Sidebar drawerWidth = {drawerWidth} mobileOpen={mobileOpen} setMobileOpen={setMobileOpen}/>
        </Box>
        <Box
        component="main"
        sx={{ flexGrow: 1, p: 2, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
        >
        <Toolbar/>
        <Outlet/>
        </Box>
    </Grid>
  )
}

export default MainLayout