import React from 'react'
import "./new.scss"
import MemberForm from '../../components/forms/MemberForm'
import Sidebar from '../../components/sidebar/Sidebar'
import Navbar from '../../components/navbar/Navbar'
import { Box, Typography } from '@mui/material'
const New = () => {
  return (
    <Box sx={{padding:4}}>
      <Typography variant='h4' gutterBottom>Ajouter un nouveau membre</Typography>
      <MemberForm/>
    </Box>
  )
}

export default New