import { Alert, Autocomplete, Button, CircularProgress, FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup, Snackbar, Stack, TextField, Typography} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton';
import React, { useState, useCallback, useEffect, useRef} from 'react'
import axios from 'axios'
import iban from 'iban'

const initialFormValues = {
  matricule: '',
  last_name: '',
  first_name: '',
  email_adress: '',
  iban_number: '',
  status: '',
  school_id: ''
}

const MemberForm = () =>{

  const [memberForm, setMemberForm] = useState(initialFormValues)
  const [formErrors, setFormErrors] = useState({})
  const [schools, setSchools] = useState([])
  const [isSubmited, setIsSubmited] = useState(false)
 
  const [openAlert, setOpenAlert] = useState(false);
  const [severityAlert, setSeverityAlert] = useState('error');
  const [messageAlert, setMessageAlert] = useState('No Message')

  const [disabledButton, setDisabledButton] = useState(false)

  const clearform = () => {
    setMemberForm(initialFormValues)
    setIsSubmited(false)
    setFormErrors({})
  }

  const handleClick = () => {
    setDisabledButton(true);
  }

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenAlert(false);
  };


  const handleChange = (e) => {
    // Update the value for the specified field
    console.log(e.target)
    const {name, value} = e.target
    setMemberForm({
      ...memberForm,
      [name]:value
    });
    console.log(memberForm)
    
  };

  const handleChangeSchool = (value) => {
    console.log("School update")
    
    console.log("school value", value)
    setMemberForm({
      ...memberForm,
      school_id:value.school_id
    })
    console.log(memberForm)
  }

  const handleSubmit = (e) =>{
    e.preventDefault();
    setFormErrors(validate(memberForm))
    setIsSubmited(true)
  }

  const removeEmptyValues = (obj) => {
    for (const key in obj) {
      if (obj[key] === null || obj[key] === undefined || obj[key] === '') {
        // Remove properties with null, undefined, or empty string values
        delete obj[key];
      } else if (typeof obj[key] === 'object') {
        // Recursively check nested objects
        removeEmptyValues(obj[key]);
  
        // Remove the property if the nested object becomes empty after cleanup
        if (Object.keys(obj[key]).length === 0) {
          delete obj[key];
        }
      }
    }
  }

  useEffect(()=>{
    fetch(`${process.env.REACT_APP_EDUCOMMUTE_LOCAL_API_ENDPOINT}/schools`)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        console.log(data);
        data = data.map(({ name, ...rest }) => ({ label: name, ...rest }));
        console.log(data);
        setSchools(data)
      })
      .catch(error => {
        var message = `Une erreur est survenue. Details : ${error}`
        setMessageAlert(message)
        setSeverityAlert('error')
        handleOpenAlert()
        console.error(error)
      })
  }, [])

  useEffect(()=>{    
    if((Object.keys(formErrors).length === 0) && (isSubmited )){
      removeEmptyValues(memberForm)
      console.log("Sending the request ...", memberForm)
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(memberForm)
      };
      
      setDisabledButton(true)
      
      fetch(`${process.env.REACT_APP_EDUCOMMUTE_LOCAL_API_ENDPOINT}/member`, requestOptions)
        .then(response=>{
          if(!response.ok){
            setSeverityAlert('error')
            return response.text()
          }else{
            setSeverityAlert("success")
            return ''
          }
          
        })
        .then(response => {
          if(!response){
            var message = 'Membre a bien été ajouter'
            var status = 'ok'
          }else{
            var message = `Une erreur est survenue, veuillez reesayer. Details : ${response}`
            var status = 'failed'
          }
          setMessageAlert(message)
          handleOpenAlert()
          setDisabledButton(false)
          return status
        })
        .then (status => {
          if(status === 'ok'){
            clearform()
          }
        })
        .catch(error => console.error(error))
     
    }
  },[formErrors])

  const validate = (values) => {
    const errors = {};
    const regex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
  
    // Matricule check
    if (!values.matricule) {
      errors.matricule = 'Matricule est obligatoire';
    }
  
    if (!values.last_name) {
      errors.last_name = 'Nom est obligatoire';
    }
  
    if (!values.first_name) {
      errors.first_name = 'Prénom est obligatoire';
    }
  
    if (values.email_adress && !regex.test(values.email_adress)) {
      errors.email_adress = "Adresse e-mail n'est pas valide";
    }
   
    if(values.iban_number && !iban.isValid(values.iban_number))(
      errors.iban_number = "Numero de compte n'est pas valable"
    )
  
    if (!values.status) {
      errors.status = 'Statut est obligatoire';
    }
  
    if (!values.school_id) {
      errors.school_id = 'Choix de l\'école est obligatoire';
    }
  
    return errors;
  };

  return(
    <form onSubmit={handleSubmit} noValidate>
    <Stack spacing={2}>
        <FormControl>
          <TextField
            name="matricule"
            label="Matricule"
            variant="outlined"
            value={memberForm.matricule}
            onChange={handleChange}
            required
          />
          <FormHelperText error>{formErrors.matricule}</FormHelperText>
        </FormControl>

        <FormControl>
          <TextField 
            name="last_name" 
            label="Nom" 
            variant="outlined"  
            value={memberForm.last_name}
            onChange={handleChange}
            required
          />
          <FormHelperText error>{formErrors.last_name}</FormHelperText>
        </FormControl>

        <FormControl>
          <TextField 
            required
            name="first_name" 
            label="Prénom" 
            variant="outlined"  
            value={memberForm.first_name}
            onChange={handleChange}
          />
          <FormHelperText error>{formErrors.first_name}</FormHelperText>
        </FormControl>

        <FormControl>
          <TextField 
            name="email_adress" 
            label="Email" 
            variant="outlined"  
            value={memberForm.email_adress}
            onChange={handleChange}
            // error={memberForm.email.error}
          />
          <FormHelperText error>{formErrors.email_adress}</FormHelperText>
        </FormControl>

        <FormControl>
          <TextField 
            name="iban_number" 
            label="IBAN" 
            variant="outlined"  
            value={memberForm.iban_number}
            onChange={handleChange}
          />
          <FormHelperText error>{formErrors.iban_number}</FormHelperText>
        </FormControl>

        <FormControl required>
          <FormLabel name="status_label">Status</FormLabel>
          <RadioGroup
            required
            row
            aria-labelledby="status_radio_btn"
            name="status"
            value={memberForm.status}
            onChange={handleChange}
          >
            <FormControlLabel value="6" control={<Radio />} label="Temporaire" />
            <FormControlLabel value="7" control={<Radio />} label="Definitif" />
          </RadioGroup>
          <FormHelperText error>{formErrors.status}</FormHelperText>
        </FormControl>
        <Autocomplete
          disablePortal
          id="combo-box-school"
          options={schools}
          loading="true"
          name="school_id"
          value={schools.find(school => school.school_id === memberForm.school_id) || null}
          onChange={(event, value) => handleChangeSchool(value)}
          renderInput={(params) => <TextField {...params} name="school_id" label="Implantation" multiline maxRows={2} />}
        />
        <FormHelperText error>{formErrors.school_id}</FormHelperText>
        <LoadingButton
          size="small"
          type="submit"
          loading={disabledButton}
          variant="contained"
        >
          <span>Ajouter membre</span>
        </LoadingButton>
    </Stack>
    <Snackbar anchorOrigin={{vertical:'top',horizontal:'center'}} open={openAlert} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severityAlert} sx={{ width: '100%' }}>
          {messageAlert}
        </Alert>
    </Snackbar>
    </form>
  )
}

export default MemberForm