import { Typography } from '@mui/material'
import React from 'react'
import { NavLink } from 'react-router-dom'

const Error = () => {
  return (
    <>
    <Typography>We are sorry this page doesn't exist!</Typography>
    <NavLink to="/">Go Back</NavLink>
    </>
  )
}

export default Error