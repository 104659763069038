import React from 'react'
import "./list.scss"
import Navbar from '../../components/navbar/Navbar'
import Sidebar from '../../components/sidebar/Sidebar'
import Datatable from '../../components/datatable/Datatable'
import { Box, Button, Typography } from '@mui/material'

const List = () => {
  return (
    <Box sx={{padding:4}}>
      <Box sx={{display:"flex", justifyContent:"space-between" }} >
        <Typography variant='h4' gutterBottom>Membres</Typography>
        <Button variant="contained" href='/members/new' size="small">Ajouter</Button>
      </Box>
      <Datatable/>
    </Box>
  )
}

export default List