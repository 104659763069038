import Home from "./pages/home/Home";
import { createBrowserRouter, Route, createRoutesFromElements, RouterProvider} from "react-router-dom"
import Login from "./pages/login/Login";
import List from "./pages/list/List";
import Single from "./pages/single/Single";
import New from "./pages/new/New";
import MainLayout from "./layouts/MainLayout";
import { ThemeProvider, createTheme } from "@mui/material";
import Error from "./pages/error/Error";

const router = createBrowserRouter(
  createRoutesFromElements(
      <Route path="/" element={<MainLayout />} errorElement={<Error />}>
        <Route index element={<Home/>} />
        <Route path="login" element={<Login />} />
        <Route path="members" element={<List />} />
        <Route path="members/new" element={<New />} />
    </Route>
  )
);

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#6439FF',
    },
    secondary: {
      main: '#ba68c8',
    },
  },
})

function App() {
  return (
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  );
}

export default App;
