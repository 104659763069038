import { AppBar, Box, IconButton, Toolbar, Typography } from '@mui/material'
import { GridMenuIcon } from '@mui/x-data-grid'
import React from 'react'

const Navbar = ({drawerWidth, mobileOpen, setMobileOpen}) => {
    
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    return (
    <AppBar
    position="fixed"
    sx={{
      width: { sm: `calc(100% - ${drawerWidth}px)` },
      ml: { sm: `${drawerWidth}px` },
      height: '50px',
    //   display: 'flex',
    //   alignItems: 'center',
      background:'white'
    }}
  >
    <Toolbar>
        <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' }, color:'primary.main', flexGrow:'0'}}
        >
            <GridMenuIcon />
        </IconButton>
        <Box sx={{
            height: "50px", 
            display: { sm: 'none' , xs:'flex'}, 
            alignItems:'center',
            justifyContent:'center',
            flexGrow:'8'}}>
            <Typography sx={{fontSize: '20px', fontWeight: 'bold', color: '#6439ff'}}>EduCommute</Typography>
        </Box>
    </Toolbar>
  </AppBar>
  )
}

export default Navbar