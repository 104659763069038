import React from 'react'
import "./home.scss"
import Sidebar from '../../components/sidebar/Sidebar'
import Navbar from '../../components/navbar/Navbar'
import Widget from '../../components/widget/Widget'

const Home = () => {
  return (
    <div className="widgets">
      <Widget type="user" />
      <Widget type="order" />
      <Widget type="erarning" />
      <Widget type="balance" />
    </div>
  )
}

export default Home